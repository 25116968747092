<template>
  <div class="step2-ai-suggestions">
    <!-- 로딩 중 화면 (spinner + 메시지) -->
    <div v-if="loading" class="loading-overlay">
      <b-spinner variant="primary" small></b-spinner>
      <p class="loading-text">{{ analysisProgress || 'AI 전략을 준비 중...' }}</p>
    </div>

    <!-- 실제 소구점 옵션 표시 화면 -->
    <div v-else>
      <h2>AI 상세페이지 전략</h2>
      <p>아래 옵션 중 하나를 선택하거나, 추가 의견을 입력해주세요.</p>

      <div class="option-cards">
        <div
          v-for="(option, index) in proposals"
          :key="index"
          class="option-card"
          :class="{ selected: selectedOption === option }"
          @click="selectOption(option)"
        >
          <!-- AI추천 라벨 (Option A만 표시, 필요시) -->
          <div v-if="option.optionId === 'Option A'" class="ai-label">AI추천</div>

          <div class="option-title">{{ option.title }}</div>

          <!-- summary -->
          <p class="option-text" v-html="formatContent(option.summary)"></p>

          <!-- expectedBenefits (배열) -->
          <ul
            v-if="option.expectedBenefits && option.expectedBenefits.length"
            class="benefits-list"
          >
            <li
              v-for="(benefit, i) in option.expectedBenefits"
              :key="i"
              v-html="formatContent(benefit)"
            ></li>
          </ul>

          <!-- additionalNotes -->
          <p
            v-if="option.additionalNotes"
            class="option-text option-notes"
            v-html="formatContent(option.additionalNotes)"
          ></p>
        </div>
      </div>

      <!-- 추가 의견 입력 -->
      <div class="d-flex justify-content-start additional-input mt-2">
        <input
          id="additionalFeedback"
          v-model="additionalFeedback"
          placeholder="추가할 소구점이 있다면 입력해주세요."
          class="form-control"
        />
      </div>

      <!-- "다음" 대신 "레이아웃 요청" 버튼으로 변경 -->
      <button @click="requestLayout" class="btn btn-primary mt-3">섹션 구성하기</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step2AISuggestions",
  props: [
    'wizardData',
    'loading',         // 부모에서 isLoading을 전달받아 로딩 표시
    'analysisProgress' // 부모에서 analysisProgress를 전달받아 진행상황 표시
  ],
  data() {
    return {
      selectedOption: null,
      additionalFeedback: ''
    }
  },
  computed: {
    proposals() {
      return this.wizardData.proposals || [];
    }
  },
  mounted() {
    // 초기 자동 선택 (Option A 우선)
    if (this.proposals.length && !this.selectedOption) {
      const aiOption = this.proposals.find(item => item.optionId === 'Option A');
      this.selectedOption = aiOption || this.proposals[0];
    }
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option;
    },
    // 부모에게 "레이아웃 구성" 이벤트를 요청
    requestLayout() {
      this.$emit('request-layout', {
        option: this.selectedOption,
        feedback: this.additionalFeedback
      });
    },
    formatContent(text = '') {
      return text
        .replace(/\. /g, '.<br/>')
        .trim();
    }
  }
}
</script>

<style scoped>
.step2-ai-suggestions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  text-align: center;
  padding: 20px;
  color: #000;
}

/* 로딩 상태 오버레이 */
.loading-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}
.loading-text {
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
}

.option-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

/* 카드 컨테이너 */
.option-card {
  position: relative;
  width: 300px;
  max-height: 450px;
  overflow-y: auto;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  padding: 15px;
  margin: 10px;
  background-color: #333;
  color: #fff;
  transition: background-color 0.3s ease;

  scrollbar-width: thin;
  scrollbar-color: #888 #444;
}
.option-card::-webkit-scrollbar {
  width: 6px;
}
.option-card::-webkit-scrollbar-track {
  background: #444;
}
.option-card::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

/* Hover & Selected */
.option-card:hover {
  background-color: #3a3a3a;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.4);
}
.option-card.selected {
  background-color: #3498db;
  color: #fff;
  border-color: #3498db;
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.7);
}

/* AI추천 라벨 */
.ai-label {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ffd700;
  color: #333;
  padding: 4px 8px;
  font-size: 0.8rem;
  border-radius: 0 0 0 8px;
  font-weight: bold;
}

/* 제목 */
.option-title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.1rem;
}

/* 본문(요약, 기타 텍스트) */
.option-text {
  line-height: 1.4;
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-wrap: break-word;
  margin-bottom: 10px;
  font-size: 90%;
}

/* expectedBenefits (배열) */
.benefits-list {
  margin: 20px 0;
  padding-left: 1.2em;
  list-style-type: disc;
  color: #fff;
  font-size: 90%;
}
.benefits-list li {
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-wrap: break-word;
  margin-bottom: 5px;
}

/* additionalNotes 구분 */
.option-notes {
  font-weight: bold;
  font-size: 90%;
}

/* 추가 의견 영역 */
.additional-input {
  margin-top: 20px;
}
.form-control {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.btn {
  padding: 10px 20px;
  margin-top: 15px;
}
</style>
