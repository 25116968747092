<template>
    <div class="step7-final-preview">
      <h2>상세페이지가 완성되었습니다!</h2>
      <!-- 최종 미리보기 영역: wizardData.finalHTML에 최종 HTML 내용이 저장되었다고 가정 -->
      <div class="final-preview-container" v-html="finalHTML"></div>
      <!-- 액션 버튼 영역 -->
      <div class="final-actions mt-3">
        <b-button variant="primary" @click="downloadHTML">HTML로 다운로드</b-button>
        <b-button variant="success" @click="publishPage">페이지로 게시하기</b-button>
        <b-button variant="warning" @click="editAgain">다시 편집하기</b-button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Step7FinalPreview",
    props: ['wizardData', 'item'],
    computed: {
      finalHTML() {
        // wizardData에 최종 HTML 콘텐츠가 저장되어 있다고 가정
        return this.wizardData.finalHTML || '<p>최종 상세페이지 미리보기가 여기에 표시됩니다.</p>';
      }
    },
    methods: {
      downloadHTML() {
        // 최종 HTML 콘텐츠를 Blob으로 생성한 후 다운로드 트리거
        const element = document.createElement('a');
        const file = new Blob([this.finalHTML], { type: 'text/html' });
        element.href = URL.createObjectURL(file);
        element.download = 'final_page.html';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      },
      publishPage() {
        // 실제 게시 로직은 API 호출이나 라우팅 등으로 구현할 수 있음
        alert('페이지가 게시되었습니다!');
      },
      editAgain() {
        // 이전 단계(예: Step6)로 돌아가서 다시 편집하도록 이벤트 emit
        this.$emit('prev');
      }
    }
  }
  </script>
  
  <style scoped>
  .step7-final-preview {
    padding: 20px;
    color: #fff;
  }
  .final-preview-container {
    border: 1px solid #ccc;
    background-color: #fff;
    color: #000;
    padding: 15px;
    min-height: 300px;
    overflow-y: auto;
    margin: 20px 0;
  }
  .final-actions {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  </style>
  