<template>
    <div class="step6-detailed-edit">
      <h2>세부 수정 (WYSIWYG 편집)</h2>
      <div class="editor-wrapper row">
        <!-- Left Panel: Section List -->
        <div class="col-md-3 section-list">
          <h4>섹션 리스트</h4>
          <ul>
            <li 
              v-for="(section, index) in sections" 
              :key="section.id || index"
              :class="{ active: selectedSectionIndex === index }"
              @click="selectSection(index)"
            >
              {{ section.title }}
            </li>
          </ul>
        </div>
        
        <!-- Center Panel: Editable Preview -->
        <div class="col-md-6 preview-area">
          <h4>미리보기</h4>
          <div 
            v-for="(section, index) in sections" 
            :key="section.id || index" 
            class="section-preview"
            :style="{ fontSize: section.style?.fontSize || '16px', color: section.style?.color || '#000' }"
            contenteditable="true"
            @input="updateSectionContent($event, index)"
          >
            <h5>{{ section.title }}</h5>
            <p>{{ section.content }}</p>
          </div>
        </div>
        
        <!-- Right Panel: Property Panel -->
        <div class="col-md-3 property-panel">
          <h4>속성 패널</h4>
          <div v-if="selectedSection">
            <div class="form-group">
              <label>폰트 사이즈</label>
              <input 
                type="text" 
                v-model="selectedSection.style.fontSize" 
                placeholder="예: 16px" 
                class="form-control" 
              />
            </div>
            <div class="form-group">
              <label>텍스트 색상</label>
              <input 
                type="color" 
                v-model="selectedSection.style.color" 
                class="form-control" 
              />
            </div>
          </div>
          <div v-else>
            <p>섹션을 선택해주세요.</p>
          </div>
        </div>
      </div>
      <!-- Navigation Buttons -->
      <div class="editor-navigation mt-3 d-flex justify-content-between">
        <b-button variant="secondary" @click="$emit('prev')">이전 단계</b-button>
        <b-button variant="primary" @click="handleNext">다음 단계</b-button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Step6DetailedEdit",
    props: ['wizardData', 'item'],
    data() {
      return {
        // wizardData.layoutSections가 전달되었다고 가정하고, 없으면 기본 배열 사용
        sections: this.wizardData.layoutSections || [
          { id: 1, title: '메인 배너 영역', content: '메인 배너 내용', style: { fontSize: '20px', color: '#333' } },
          { id: 2, title: '기능 스펙 강조 섹션', content: '주요 기능 및 스펙 내용', style: { fontSize: '16px', color: '#555' } },
          { id: 3, title: '가성비 할인 이벤트 섹션', content: '할인 이벤트 내용', style: { fontSize: '16px', color: '#777' } },
          { id: 4, title: '디자인 디테일 섹션', content: '디자인 디테일 내용', style: { fontSize: '16px', color: '#888' } },
          { id: 5, title: '추가 안내 섹션', content: 'FAQ 및 추가 안내 내용', style: { fontSize: '16px', color: '#999' } }
        ],
        selectedSectionIndex: 0
      }
    },
    computed: {
      selectedSection() {
        return this.sections[this.selectedSectionIndex];
      }
    },
    methods: {
      selectSection(index) {
        this.selectedSectionIndex = index;
      },
      updateSectionContent(event, index) {
        // contenteditable 영역의 텍스트 변경을 반영하여 sections 배열 업데이트
        const updatedText = event.target.innerText;
        // 예시로, 단순히 content 필드 업데이트 (실제 프로젝트에서는 HTML 구조를 유지할 수 있음)
        this.sections[index].content = updatedText;
      },
      handleNext() {
        // 수정된 섹션 배열을 상위 컴포넌트로 전달
        this.$emit('next', { layoutSections: this.sections });
      }
    }
  }
  </script>
  
  <style scoped>
  .step6-detailed-edit {
    padding: 20px;
    color: #fff;
  }
  .editor-wrapper {
    border: 1px solid #444;
    border-radius: 4px;
    overflow: hidden;
    background: #2c2c2c;
    padding: 10px;
  }
  .section-list ul {
    list-style: none;
    padding: 0;
  }
  .section-list li {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #444;
  }
  .section-list li.active {
    background-color: #3498db;
    font-weight: bold;
  }
  .preview-area {
    padding: 10px;
    background-color: #fff;
    color: #000;
    min-height: 300px;
    overflow-y: auto;
  }
  .section-preview {
    border: 1px dashed #ccc;
    padding: 10px;
    margin-bottom: 10px;
  }
  .property-panel .form-group {
    margin-bottom: 15px;
  }
  .property-panel label {
    display: block;
    margin-bottom: 5px;
  }
  .editor-navigation {
    margin-top: 20px;
  }
  </style>
  