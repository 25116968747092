<template>
    <div class="step5-copy-style">
      <h2>카피 & 스타일 제안</h2>
      <p>각 섹션별로 AI가 제안한 카피와 스타일을 확인하고, 원하는 옵션을 채택하거나 수정 요청을 할 수 있습니다.</p>
      <div class="proposal-cards">
        <div
            v-for="proposal in proposals"
            :key="proposal.id"
            class="proposal-card"
            :class="{ selected: selectedProposal && selectedProposal.id === proposal.id }"
            >
          <h4>{{ proposal.section }}</h4>
          <p><strong>헤드라인:</strong> {{ proposal.headline }}</p>
          <p><strong>카피:</strong> {{ proposal.copy }}</p>
          <p><strong>이미지 제안:</strong> {{ proposal.imageIdea }}</p>
          <p><strong>스타일:</strong> {{ proposal.style }}</p>
          <div class="proposal-actions">
            <b-button size="sm" variant="primary" @click="adoptProposal(proposal)">채택하기</b-button>
            <b-button size="sm" variant="warning" @click="requestModification(proposal)">수정 요청</b-button>
          </div>
        </div>
      </div>
      <div class="additional-feedback mt-3">
        <label for="feedback">추가 의견</label>
        <input
          id="feedback"
          v-model="additionalFeedback"
          placeholder="추가 의견이 있다면 입력해주세요."
          class="form-control"
        />
      </div>
      <b-button class="mt-3" variant="primary" @click="handleNext">다음</b-button>
    </div>
  </template>
  
  <script>
  export default {
    name: "Step5CopyStyle",
    props: ['wizardData'],
    data() {
      return {
        proposals: [
          {
            id: 1,
            section: '메인 배너 영역',
            headline: '한 번의 충전으로 하루 종일!',
            copy: '탁월한 배터리 성능으로 하루 종일 지속되는 힘을 경험하세요.',
            imageIdea: '제품 확대 컷 및 심플 배경',
            style: '폰트 Bold, 색상 #333'
          },
          {
            id: 2,
            section: '기능 스펙 강조 섹션',
            headline: '최고의 성능, 최고의 선택',
            copy: '기능과 스펙을 한눈에, 고객이 만족하는 제품입니다.',
            imageIdea: '디테일 샷 및 기능 아이콘',
            style: '폰트 Medium, 색상 #555'
          },
          {
            id: 3,
            section: '가성비 할인 이벤트 섹션',
            headline: '가성비를 넘어선 할인',
            copy: '가격 대비 최고의 성능, 특별 할인 이벤트로 만나보세요.',
            imageIdea: '가격 강조 그래픽',
            style: '폰트 Regular, 색상 #777'
          }
        ],
        selectedProposal: null,
        additionalFeedback: ''
      }
    },
    methods: {
      adoptProposal(proposal) {
        this.selectedProposal = proposal;
      },
      requestModification(proposal) {
        // 수정 요청 시 별도의 로직을 추가할 수 있음.
        // 현재는 선택된 제안으로 표시하고 알림을 보여줍니다.
        this.selectedProposal = proposal;
        alert(`"${proposal.section}" 섹션의 카피/스타일에 대해 수정 요청을 보냈습니다.`);
      },
      handleNext() {
        // 선택된 제안과 추가 의견을 상위 컴포넌트로 전달
        this.$emit('next', {
          copyStyleProposal: this.selectedProposal,
          additionalFeedback: this.additionalFeedback
        });
      }
    }
  }
  </script>
  
  <style scoped>
  .step5-copy-style {
    padding: 20px;
    color: #fff;
  }
  .proposal-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  .proposal-card {
    background: #1a1a1a;
    border: 1px solid #3498db;
    border-radius: 4px;
    padding: 15px;
    width: calc(33.333% - 10px);
    box-sizing: border-box;
    transition: all 0.3s ease;
  }
  .proposal-card.selected {
    background: #ecf6fd;
    border-color: #3498db;
    color: #333;
  }
  .proposal-card h4 {
    margin-top: 0;
  }
  .proposal-actions {
    margin-top: 10px;
    display: flex;
    gap: 10px;
  }
  .additional-feedback label {
    display: block;
    margin-bottom: 5px;
  }
  .form-control {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  </style>
  