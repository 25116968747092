<template>
  <div class="step3-layout-preview">
    <!-- 미리보기 스위치 -->
    <div class="custom-control custom-switch custom-control-inline mb-3 switch-container">
      <input
        type="checkbox"
        class="custom-control-input bg-primary"
        id="previewSwitch"
        v-model="previewMode"
      />
      <label class="custom-control-label" for="previewSwitch">
        미리보기
      </label>
    </div>

    <h2>초안 레이아웃 미리보기</h2>
    <p>
      AI가 제안한 상세페이지 섹션들을 확인하고,<br />
      <span v-if="previewMode">
        상세페이지가 하나로 긴 형태로 보이도록 미리보기 상태입니다.
      </span>
      <span v-else>
        드래그앤드롭으로 순서를 조정하거나, 이유/노트 말풍선을 확인하세요.
      </span>
    </p>

    <!-- Draggable (미리보기 모드일 때 비활성) -->
    <draggable
      v-model="sections"
      class="sections-list"
      @end="handleDragEnd"
      :disabled="previewMode"
    >
      <!-- 각 섹션 반복 -->
      <div
        v-for="section in sections"
        :key="section.id"
        class="section-item"
        :class="{ 'preview-mode': previewMode }"
        :style="section.sectionCss" 
      >
        <div class="section-content">
          <!-- 각 섹션 안의 블록 반복 -->
          <div
            v-for="block in section.blocks"
            :key="block.blockId"
            class="full-html-content"
            v-html="block.fullHTML"
          ></div>
        </div>

        <!-- 편집 모드에서만 reason/notes 보여줌 -->
        <template v-if="!previewMode">
          <!-- 이유/목적 (왼쪽 말풍선) -->
          <div v-if="section.reason" class="speech-bubble-left">
            <strong>이유/목적</strong><br />
            {{ section.reason }}
          </div>

          <!-- 노트(오른쪽 말풍선) -->
          <div v-if="hasAnyNotes(section.blocks)" class="speech-bubble-right">
            <div
              v-for="block in section.blocks"
              :key="block.blockId + '-notes'"
              class="notes-block"
            >
              <div v-if="block.notes">
                <hr />
                <strong>노트 (Block {{ block.blockId }}):</strong><br />
                {{ block.notes }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </draggable>

    <!-- '레이아웃 확정' 버튼 -->
    <b-button class="mt-3" variant="primary" @click="confirmLayout">
      레이아웃 확정
    </b-button>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: "Step3LayoutPreview",
  props: ['wizardData'],
  components: { draggable },
  data() {
    return {
      previewMode: false,
      // wizardData.layoutSections가 있으면 그걸 사용,
      // 없으면 예시 데이터(1개 섹션)로 대체
      sections: this.wizardData.layoutSections?.length
        ? this.wizardData.layoutSections
        : [
            {
              id: 1,
              title: "예시 섹션",
              reason: "샘플 섹션에 대한 이유",
              sectionCss: "padding-bottom: 30px;",
              blocks: [
                {
                  blockId: 1,
                  heading: "예시 블록 헤딩",
                  copy: "예시 블록 본문",
                  fullHTML: `
                    <div style='font-size:24px; line-height:1.4; margin-bottom:10px;'>
                      <b>데모 fullHTML</b> : 이미 인라인 스타일이 포함된 상태
                    </div>
                  `,
                  notes: "예시 노트"
                }
              ]
            }
          ]
    };
  },
  methods: {
    // 드래그 완료 -> 상위 컴포넌트에 갱신
    handleDragEnd() {
      this.$emit('update', { layoutSections: this.sections });
    },
    // '레이아웃 확정' 버튼
    confirmLayout() {
      this.$emit('update', { layoutSections: this.sections });
    },
    // 블록 중 하나라도 notes가 있으면 true
    hasAnyNotes(blocks) {
      return blocks.some(block => block.notes);
    }
  }
};
</script>

<style scoped>
.step3-layout-preview {
  /* 기존 스타일 유지 - CSS 수정 없음 */
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  color: #000;
}

.switch-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.sections-list {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px;
  margin-top: 20px;
  background-color: #fff;
}

.section-item {
  position: relative;
  border: 1px solid #666;
  border-radius: 4px;
  margin-bottom: 30px;
  background-color: #fafafa;
  cursor: move; 
  padding: 10px;
}
.section-item:last-child {
  margin-bottom: 0;
}

/* 미리보기 모드 */
.section-item.preview-mode {
  border: none;
  cursor: default;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 10px;
}

.section-content {
  margin-top: 20px;
  margin-bottom: 10px;
}
.full-html-content {
  margin-bottom: 15px;
}

/* 공통 라인 높이 & 폰트 (예시) */
:deep(.section-item) {
  line-height: 1.6;
  font-size: 1rem;
}

/* 말풍선 (왼) */
.speech-bubble-left {
  position: absolute;
  top: 20px;
  left: -220px;
  width: 200px;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 8px;
  padding: 10px;
  text-align: left;
}
.speech-bubble-left::after {
  content: "";
  position: absolute;
  top: 20px;
  right: -10px;
  border-top: 10px solid transparent;
  border-left: 10px solid #bbb;
  border-bottom: 10px solid transparent;
}
/* 말풍선 (오른) */
.speech-bubble-right {
  position: absolute;
  top: 20px;
  right: -220px;
  width: 200px;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 8px;
  padding: 10px;
  text-align: left;
}
.speech-bubble-right::after {
  content: "";
  position: absolute;
  top: 20px;
  left: -10px;
  border-top: 10px solid transparent;
  border-right: 10px solid #bbb;
  border-bottom: 10px solid transparent;
}

.notes-block hr {
  border: none;
  border-top: 1px dashed #ccc;
  margin: 8px 0;
}
</style>
