<template>
    <div class="progress-bar-container">
      <!-- 진행률 바 -->
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{ width: progressPercent + '%' }"
          :aria-valuenow="progressPercent"
          aria-valuemin="0"
          :aria-valuemax="100"
        ></div>
      </div>
      <!-- 단계별 라벨 표시 -->
      <div class="steps-labels">
        <div
          v-for="(step, index) in steps"
          :key="index"
          class="step-label"
          :class="{ active: index < currentStep }"
        >
          <span>{{ index + 1 }}. {{ step }}</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ProgressBar",
    props: {
      currentStep: {
        type: Number,
        required: true
      },
      steps: {
        type: Array,
        required: true
      }
    },
    computed: {
      progressPercent() {
        return (this.currentStep / this.steps.length) * 100;
      }
    }
  }
  </script>
  
  <style scoped>
  .progress {
    height: 10px; /* 얇게 */
    background-color: #e9ecef;
    border-radius: 10px;
    overflow: hidden;
  }
  .progress-bar {
    background-color: #3498db;
    transition: width 0.3s ease;
    border-radius: 10px;
  }
  .steps-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 5px; /* 기존 10px에서 줄임 */
  }
  .step-label {
    font-size: 0.85rem;
    color: #ccc;
  }
  .step-label.active {
    color: #3498db;
    font-weight: bold;
  }
  </style>
  