<template>
  <div class="step1-input">
    <h2>시장 분석</h2>
    
    <!-- 로딩 중이 아닐 때 -->
    <div v-if="!loading">
      <p>시장 분석을 위해 판매할 타겟 키워드를 입력해주세요.</p>
      <div class="form-group">
        <input
          id="salesKeyword"
          v-model="salesKeyword"
          placeholder="예: 무선청소기"
          class="form-control text-center"
        />
      </div>
      <!-- "키워드 분석하기" 버튼을 누르면 부모에 'request-analysis' 이벤트 전달 -->
      <b-button @click="requestAnalysis" variant="primary">키워드 분석하기</b-button>
    </div>
    
    <!-- 로딩 중일 때 -->
    <div v-else>
      <p>분석 중입니다. 잠시만 기다려주세요...</p>
      <p class="text-success">{{ analysisProgress }}</p>
      <b-spinner style="width:3rem; height:3rem;" class="mt-2"></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step1Input",
  props: ['wizardData', 'item', 'loading', 'analysisProgress'],
  data() {
    return {
      salesKeyword: this.wizardData.salesKeyword || ''
    }
  },
  methods: {
    // 기존 handleNext -> 'requestAnalysis'로 변경
    // 분석 요청만 부모로 전달, 단계 이동은 부모가 판단하여 처리
    requestAnalysis() {
      this.$emit('request-analysis', { salesKeyword: this.salesKeyword });
    }
  }
}
</script>

<style scoped>
.step1-input {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  text-align: center;
}
.form-group {
  margin-bottom: 15px;
  width: 100%;
  max-width: 400px;
}
</style>
