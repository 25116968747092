<template>
  <b-modal
    :id="'makePageModal' + item.등록번호"
    size="xl"
    hide-header
    hide-footer
    v-model="showModal"
    dialog-class="full-screen-modal"
    @hidden="closeWizard"
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="modal-wrapper">
      <!-- 우측 상단 닫기 버튼 -->
      <b-button variant="link" class="modal-close-button" @click="finishWizard">
        <span aria-hidden="true">&times;</span>
      </b-button>

      <div class="wizard-container px-5">
        <!-- 상단 헤더: ProgressBar 고정(sticky) -->
        <div class="wizard-header">
          <progress-bar
            :current-step="currentStep"
            :steps="stepsTitles"
            class="thin-progress"
          />
        </div>

        <!-- 중앙 스크롤 영역 -->
        <div class="wizard-content">
          <component
            :is="currentComponent"
            :wizard-data="wizardData"
            :item="item"
            :loading="isLoading"
            :analysis-progress="analysisProgress"

            @request-analysis="onRequestAnalysis"
            @request-layout="onRequestLayout"
            @next="handleNextStep"
            @prev="prevStep"
            @update="updateWizardData"
          />
        </div>

        <!-- 하단 버튼 -->
        <div class="wizard-navigation">
          <b-button
            v-if="currentStep > 1"
            @click="prevStep"
            variant="secondary"
            class="mx-2"
          >
            이전 단계
          </b-button>

          <b-button
            v-if="currentStep < steps.length"
            @click="handleNextStep"
            variant="primary"
            class="mx-2"
          >
            다음 단계
          </b-button>

          <b-button
            v-else
            @click="finishWizard"
            variant="success"
            class="mx-2"
          >
            완료
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Step1Input from './Step1Input.vue';
import Step2AISuggestions from './Step2AISuggestions.vue';
import Step3LayoutPreview from './Step3LayoutPreview.vue';
import Step4CopyStyle from './Step4CopyStyle.vue';
import Step5DetailedEdit from './Step5DetailedEdit.vue';
import Step6FinalPreview from './Step6FinalPreview.vue';
import ProgressBar from './ProgressBar.vue';

export default {
  name: "ProductDetailWizard",
  props: ['item'],
  components: {
    Step1Input,
    Step2AISuggestions,
    Step3LayoutPreview,
    Step4CopyStyle,
    Step5DetailedEdit,
    Step6FinalPreview,
    ProgressBar
  },
  data() {
    return {
      showModal: false,
      currentStep: 1,
      isLoading: false,
      analysisProgress: '',

      steps: [
        Step1Input,
        Step2AISuggestions,
        Step3LayoutPreview,
        Step4CopyStyle,
        Step5DetailedEdit,
        Step6FinalPreview
      ],
      stepsTitles: [
        '시장 분석',
        '전략 기획',
        '레이아웃 구성',
        '카피 및 스타일',
        '세부 수정',
        '최종 완성'
      ],
      wizardData: {}
    };
  },
  computed: {
    currentComponent() {
      return this.steps[this.currentStep - 1];
    }
  },
  mounted() {
    if (this.item && this.item.wizardData) {
      this.wizardData = { ...this.item.wizardData };
    }
    if (this.item && this.item.currentStep) {
      this.currentStep = this.item.currentStep;
    }

    this.messageHandler = (event) => {
      if (!event.data) return;

      if (event.data.type === 'ANALYZE_PROGRESS') {
        this.analysisProgress = event.data.payload;
      }
      else if (event.data.type === 'ANALYZE_DONE') {
        this.isLoading = false;
        this.analysisProgress = '';
        if (event.data.result.error) {
          alert(event.data.result.result + "\n" + event.data.result.error);
        } else {
          this.wizardData.analysisResult = event.data.result.result || {};
          if (event.data.result.result?.proposals) {
            this.wizardData.proposals = event.data.result.result.proposals;
            this.wizardData.labeledImages = event.data.result.labeledImages;
          }
          if (this.currentStep === 1) {
            this.currentStep = 2;
          }
          this.autoSaveData();
        }
      }
      else if (event.data.type === 'ANALYZE_LAYOUT_PROGRESS') {
        this.analysisProgress = event.data.payload || '레이아웃 생성 중...';
      }
      else if (event.data.type === 'ANALYZE_LAYOUT_DONE') {
        this.isLoading = false;
        this.analysisProgress = '';
        if (event.data.result?.sections) {
          this.wizardData.layoutSections = event.data.result.sections;
        }
        if (this.currentStep === 2) {
          this.currentStep = 3;
        }
        this.autoSaveData();
      }
    };
    window.addEventListener('message', this.messageHandler);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.messageHandler);
  },
  methods: {
    onRequestAnalysis(payload) {
      const { salesKeyword } = payload;
      this.wizardData.salesKeyword = salesKeyword;

      if (!this.$store.state.settings[0].geminiAPIKey) {
        alert('계정관리 > 설정 > Gemini API 설정 에서 Gemini API Key를 먼저 설정해 주세요');
        return;
      }
      this.isLoading = true;
      this.analysisProgress = '키워드 정보 수집 중...';

      window.postMessage({
        type: 'REQUEST_ANALYZE_KEYWORD',
        keyword: salesKeyword,
        apiKey: this.$store.state.settings[0].geminiAPIKey,
        productInfo: this.item
      }, '*');
    },

    onRequestLayout(data) {
      this.wizardData.selectedOption = data.option;
      this.wizardData.additionalFeedback = data.feedback;

      if (!this.$store.state.settings[0].geminiAPIKey) {
        alert('계정관리 > 설정 > Gemini API Key를 먼저 설정해 주세요');
        return;
      }
      this.isLoading = true;
      this.analysisProgress = '상세페이지 섹션 구성 요청 중...';

      window.postMessage({
        type: 'REQUEST_LAYOUT',
        payload: data,
        apiKey: this.$store.state.settings[0].geminiAPIKey,
        productInfo: this.item,
        labeledImages: this.wizardData.labeledImages
      }, '*');
    },

    handleNextStep() {
      if (this.currentStep < this.steps.length) {
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },

    updateWizardData(data) {
      this.wizardData = { ...this.wizardData, ...data };
      this.autoSaveData();
    },
    autoSaveData() {
      if (this.item) {
        this.item.wizardData = { ...this.wizardData };
        this.item.currentStep = this.currentStep;
      }
      this.$emit('auto-save', this.item);
    },
    finishWizard() {
      this.autoSaveData();
      this.showModal = false;
    },
    closeWizard() {
      this.$emit('closed');
    }
  }
};
</script>

<style scoped>
.modal-wrapper {
  position: relative;
  height: 100%;
}

.wizard-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff; /* 라이트 배경 */
  color: #000;           /* 검정 폰트 */
}

/* 헤더(ProgressBar) */
.wizard-header {
  position: sticky;
  top: 0;
  background-color: #fff; /* 라이트 배경 */
  z-index: 10;
  padding: 10px 0;
}

/* 중앙 스크롤 영역 */
.wizard-content {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 80px; /* 하단버튼 영역 고려 */
}

/* 하단 버튼 */
.wizard-navigation {
  position: relative;
  text-align: center;
  padding: 20px 0;
  background-color: #f9f9f9; /* 라이트 톤 백그라운드(옵션) */
}

/* 닫기 버튼 */
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  font-size: 24px;
  background: transparent;
  border: none;
  z-index: 20;
}

/* 전체 모달 (full screen) */
:deep(.full-screen-modal) {
  width: 100vw !important;
  max-width: 100vw !important;
  margin: 0 !important;
  padding: 0 !important;
}
:deep(.full-screen-modal .modal-content) {
  width: 100vw;
  height: 100vh !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  background-color: #fff; /* 라이트 모드 */
}
:deep(.full-screen-modal .modal-body) {
  flex: 1;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

/* 얇은 progress 스타일 */
.thin-progress .progress {
  height: 5px !important;
  background-color: #eee; /* progress track 라이트 */
}

</style>
